import templates from './templates';
import staticData from './json/data.json';
import showlinks from './json/showlinks.json';
import './stylesheet/stylesheet.scss';
function tmpl(name, vars) {
    return templates[name](vars);
}
var supportedLangages = ["en", "fr"];
var languageParam = /[?&]langage=([^&]*)/.exec(window.location.search || '');
console.log(languageParam);
var langage;
var otherLangage;
var docBody = document.body;
var data = { "Shows": [] };
var dataPath = '/errorassets/data/showinfo.json';
var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
        init(JSON.parse(xhttp.responseText));
    }
};
xhttp.open("GET", dataPath, true);
xhttp.send();
function init(Data) {
    setLangage();
    data["Shows"] = purifyData(Data);
    data["show_links"] = showlinks;
    data["static_data"] = staticData[langage];
    data["static_data"]["langage"] = langage;
    data = sortDataAlphabetically(data, true);
    getLocation();
    loadPage(docBody, data);
}
function purifyData(fullData) {
    var newData = [];
    fullData.Shows.filter(function (s) { return s.ShowName != "Cirque du Soleil Monaco"; }).map(function (show, k) {
        newData.push({});
        newData[k]["WebRuns"] = [];
        show.WebRuns.map(function (run, l) {
            newData[k]["WebRuns"].push({});
            newData[k]["WebRuns"][l]["City"] = {
                "CityMnemonic": run.City.CityMnemonic,
                "Latitude": run.City.Latitude,
                "Longitude": run.City.Longitude,
                "Name": run.City.Name,
                "Country": {
                    "Name": run.City.Country.Name
                }
            };
            newData[k]["WebRuns"][l]["BookingPhoneNumber"] = run.BookingPhoneNumber;
            newData[k]["WebRuns"][l]["RunStartDate"] = run.RunStartDate;
            newData[k]["WebRuns"][l]["RunEndDate"] = run.RunEndDate;
            newData[k]["WebRuns"][l]["TicketLinks"] = run.TicketLinks;
        });
        newData[k]["ShowId"] = show.ShowId;
        newData[k]["ShowName"] = show.ShowName;
        newData[k]["Names"] = show.Names.HtmlName;
    });
    for (var i = 0; i < newData.length; i++) {
        for (var j = 0; j < newData[i]["WebRuns"].length; j++) {
            if (newData[i]["WebRuns"][j].TicketLinks == undefined) {
                newData[i]["WebRuns"].splice(j, 1);
                j -= 1;
            }
        }
        if (newData[i]["WebRuns"] == undefined && newData[i]["WebRuns"].length == 0) {
            newData.splice(i, 1);
            i -= 1;
        }
    }
    return newData;
}
function getBrowserLangage() {
    var browserLangage = (window.navigator.userLanguage || window.navigator.language);
    if (/^en/.test(browserLangage)) {
        return "en";
    }
    else if (/^fr/.test(browserLangage)) {
        return "fr";
    }
}
function setLangage() {
    langage = ((languageParam != null) ? languageParam[1].toLowerCase() : getBrowserLangage());
    console.log(languageParam, langage);
    if (supportedLangages.indexOf(langage) === -1) {
        langage = "en";
    }
    if (langage == "en") {
        otherLangage = "fr";
    }
    else {
        otherLangage = "en";
    }
}
function getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(sortDataByGeolocation);
    }
}
function distanceFromUser(userPosition, servicePosition) {
    var toRadians = function (degree) {
        return degree * (Math.PI / 180);
    };
    var lat1 = userPosition.coords.latitude;
    var lon1 = userPosition.coords.longitude;
    var lat2 = servicePosition.Latitude;
    var lon2 = servicePosition.Longitude;
    var earthRadius = 6371e3;
    var lat1Rad = toRadians(lat1);
    var lat2Rad = toRadians(lat2);
    var deltaLat = toRadians(lat2 - lat1);
    var deltaLon = toRadians(lon2 - lon1);
    var a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (earthRadius * c);
}
function sortDataByGeolocation(userPosition) {
    for (var i = 0; i < data["Shows"].length; i++) {
        data["Shows"][i].WebRuns.sort(function (a, b) { return distanceFromUser(userPosition, a.City) - distanceFromUser(userPosition, b.City); });
    }
    data["Shows"].sort(function (a, b) {
        var dist = distanceFromUser(userPosition, a.WebRuns[0].City) - distanceFromUser(userPosition, b.WebRuns[0].City);
        return dist;
    });
    loadPage(docBody, data);
}
[];
function sortWebRun(webrun) {
    webrun.sort(function (a, b) {
        if (a.City.Name.find(function (obj) { return obj.LanguageCode == langage; }).Value < b.City.Name.find(function (obj) { return obj.LanguageCode == langage; }).Value) {
            return -1;
        }
        if (a.City.Name.find(function (obj) { return obj.LanguageCode == langage; }).Value > b.City.Name.find(function (obj) { return obj.LanguageCode == langage; }).Value) {
            return 1;
        }
        return 0;
    });
    return webrun;
}
function sortDataAlphabetically(input, sortRuns) {
    if (sortRuns === void 0) { sortRuns = false; }
    input["Shows"].sort(function (a, b) {
        if (a.ShowName < b.ShowName)
            return -1;
        if (a.ShowName > b.ShowName)
            return 1;
        return 0;
    });
    if (sortRuns) {
        for (var i = 0; i < input["Shows"].length; i++) {
            input["Shows"][i]["WebRuns"] = sortWebRun(input["Shows"][i]["WebRuns"]);
        }
    }
    return input;
}
function padder() {
    var padder = document.createElement("div");
    padder.classList.add("padder");
    return padder;
}
function banner(data) {
    var bannerdiv = document.createElement("div");
    bannerdiv.className += "banner bannerbackgroundcontainer";
    bannerdiv.innerHTML = tmpl("banner-tmpl", data);
    return bannerdiv;
}
function header(data) {
    var headerdiv = document.createElement("div");
    headerdiv.className += " imagebackgroundcontainer -center-items";
    var header = document.createElement('header');
    header.innerHTML = tmpl("header-tmpl", data);
    headerdiv.appendChild(padder());
    headerdiv.appendChild(header);
    headerdiv.appendChild(padder());
    return headerdiv;
}
function shows(data) {
    var blackbg = document.createElement("div");
    blackbg.className += " blackbackgroundcontainer -center-items";
    var showsdiv = document.createElement("div");
    showsdiv.classList.add("shows");
    showsdiv.innerHTML = tmpl("shows-tmpl", data);
    blackbg.appendChild(padder());
    blackbg.appendChild(showsdiv);
    blackbg.appendChild(padder());
    return blackbg;
}
function repertory(data) {
    var repertorydiv = document.createElement("div");
    repertorydiv.className += " whitebackgroundcontainer -center-items";
    var repertory = document.createElement('div');
    repertory.classList.add("repertory__div");
    repertory.innerHTML = tmpl("repertory-tmpl", data);
    repertorydiv.appendChild(padder());
    repertorydiv.appendChild(repertory);
    repertorydiv.appendChild(padder());
    return repertorydiv;
}
function footer(data) {
    var footerdiv = document.createElement("div");
    footerdiv.className += " blackbackgroundcontainer -center-items";
    var footer = document.createElement('footer');
    footer.innerHTML = tmpl("footer-tmpl", data);
    footerdiv.appendChild(padder());
    footerdiv.appendChild(footer);
    footerdiv.appendChild(padder());
    return footerdiv;
}
function loadPage(body, usedData) {
    usedData["static_data"]["langage_switch_url"] = ("index.html?langage=" + otherLangage);
    var containers = ["image", "black", "white", "banner"];
    containers.map(function (container, k) {
        var elements = document.getElementsByClassName(container + "backgroundcontainer");
        for (var i = 0; i < elements.length; i++) {
            elements[i].parentNode.removeChild(elements[i]);
            i -= 1;
        }
    });
    // body.appendChild(banner(usedData));
    body.appendChild(header(usedData));
    body.appendChild(shows(usedData));
    body.appendChild(repertory(usedData));
    body.appendChild(footer(usedData));
    var script = document.createElement("script");
    script.innerHTML = "setCustomSelectsEventListeners();";
    body.appendChild(script);
}
